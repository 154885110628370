import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const About = () => (
  <Layout>
    <SEO title="About Colin" />
    <div className="article max-w-lg">
      <h1 className="text-4xl">About Colin</h1>
      <div className="max-w-md">
        <p>
          I live in North Suffolk and work in my straw-bale shed which I built a few years ago at
          the top of our garden. I studied art at Bath Academy of Art in Corsham and taught in
          Secondary schools in Yorkshire and Norfolk.
        </p>
      </div>
      <StaticQuery
        query={graphql`
          query {
            colinInHisStudio: file(relativePath: { eq: "about/colin-in-his-studio.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            strawBaleStudio: file(relativePath: { eq: "about/straw-bale-studio.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <div class="sm:flex sm:-mx-4 -my-4 sm:my-8">
            <div class="sm:w-1/2 sm:px-4 py-4 sm:py-0">
              <Img fluid={data.colinInHisStudio.childImageSharp.fluid} />
            </div>
            <div class="sm:w-1/2 sm:px-4 py-4 sm:py-0">
              <Img fluid={data.strawBaleStudio.childImageSharp.fluid} />
            </div>
          </div>
        )}
      />
      <div className="max-w-md">
        <h2>Art Statement</h2>
        <p>
          At first sight, my work may seem rather diverse, so I feel it is important to explain the
          reasons for this. Much of my work is spent exploring media, different techniques and
          materials. Subject matter and ideas usually invite a particular use of materials to enable
          ideas to be effectively expressed.
        </p>
        <p>
          For example, my large narrative drawings require the intense blackness of different
          hardnesses of charcoal pencil, (as opposed to grey graphite pencil) which consequently,
          have to be made needle sharp through constant sharpening. Careful masking has to be
          employed to prevent smudging.
        </p>
        <p>
          At the other extreme, where chance can be exploited, the saturated colour of the land and
          seascapes in Greece can be described using the chance puddling of watercolour on the
          non-absorbent surface of primed MDF, having applied watercolour masking fluid to delineate
          shapes in the design. I allow the paint to slowly dry and then, sometimes, I slightly tip
          the board so that the paint settles more thickly in certain areas.
        </p>
        <p>
          I describe the details of these techniques to illustrate my interest in ensuring that my
          work undergoes changes and renewal.
        </p>
        <p>
          This open-ended exploratory way of working constantly creates changes of style and manner,
          which hopefully, makes the body of work interesting.
        </p>
      </div>
    </div>
  </Layout>
)

export default About
